import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { RootState } from "../../app/store";

export const getNotificationList = createAsyncThunk(
  "notification/getlist",
  async (query: any, thunkAPI) => {
    try {
      let getAllNotificationUrl = `/api/notification/list`;
      let url = `${getAllNotificationUrl}?sortBy=${query.sortBy}&sortOrder=${query.sortOrder}&page=${query.page}&perPage=${query.perPage}`;
      const response = await axios({
        method: "POST",
        url: url,
        data: query,
      });
      let data = await response.data;
      if (response.status === 200) {
        console.log(data)
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      console.log("Error", e);
      thunkAPI.rejectWithValue(e);
    }
  }
);

export const notificationSlice = createSlice({
  name: "notification",
  initialState: {
    notification: {},
    notificationList: { notifications: [], total: 0 },
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: "",
  },
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getNotificationList.pending, (state) => {
        state.isFetching = true;
        state.notificationList = { notifications: [], total: 0 };
      })
      .addCase(getNotificationList.fulfilled, (state, action) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.notificationList = action.payload.data;
      })
      .addCase(getNotificationList.rejected, (state, action) => {
        // state.errorMessage = action.payload.message;
        state.isFetching = false;
        state.isError = true;
      });
  },
});

export const { clearState } = notificationSlice.actions;

export const notificationSelector = (state: RootState) => state.notification;
export const notificationListSelector = (state: RootState) =>
  state.notification.notificationList;

export default notificationSlice.reducer;
