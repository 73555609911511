import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import axios from "axios";

export const getTagList = createAsyncThunk(
    "resource-tag/get",
    async (query: any, thunkAPI) => {
      try {
        let url = `/api/resource-tags?page=${query.page}&perPage=${query.perPage}`;
        const response = await axios({
          method: "POST",
          url: url,
          data: query
        });
        let data = await response.data;
        // console.log("data", data)
        if (response.status === 200) {
          return data;
        } else {
          return thunkAPI.rejectWithValue(response.data);
        }
      } catch (e: any) {
        console.log("Error", e.response.data);
        thunkAPI.rejectWithValue(e.response.data);
      }
    }
  );

  export const addTag = createAsyncThunk(
    "resource-tag/add",
    async (tagData: any, thunkAPI) => {
      try {
        let url = `/api/resource-tags/insert`;
        const response = await axios({
          method: "POST",
          url: url,
          data: tagData
        });
        let data = await response.data;
        if (response.status === 200) {
          return data;
        } else {
          return thunkAPI.rejectWithValue(response.data);
        }
      } catch (e: any) {
        console.log("Error", e);
        return thunkAPI.rejectWithValue(e);
      }
    }
  )

  export const deleteTag = createAsyncThunk(
    "resource-tag/delete",
    async (tagData: any, thunkAPI) => {
      try {
        let url = `/api/resource-tags/delete?id=${tagData.id}`;
        const response = await axios({
          method: "DELETE",
          url: url,
        });
        let data = await response.data;
        if (response.status === 200) {
          return data;
        } else {
          return thunkAPI.rejectWithValue(response.data);
        }
      } catch (e: any) {
        console.log("Error", e.response.data);
        thunkAPI.rejectWithValue(e.response.data);
      }
    }
  )

  export const tagListSlice = createSlice({
    name: "tagList",
    initialState: {
      tagList: {
        name: "",
        isActive: "",
        _id:""
      },
      isFetching: false,
      isSuccess: false,
      isError: false,
      errorMessage: "",
    },
    reducers: {
      clearState: (state) => {
        state.isError = false;
        state.isSuccess = false;
        state.isFetching = false;
      },
    },
    extraReducers: (builder) => {
      builder
        .addCase(getTagList.pending, (state) => {
          state.isFetching = true;
        })
        .addCase(getTagList.fulfilled, (state, action) => {
          state.tagList = action.payload.data;
          state.isFetching = false;
          state.isSuccess = true;
        })
        .addCase(getTagList.rejected, (state, action) => {
          state.isFetching = false;
          state.isError = true;
        })
        .addCase(addTag.pending, (state) => {
          state.isFetching = true;
        })
        .addCase(addTag.fulfilled, (state, action) => {
          state.isFetching = false;
          state.isSuccess = true;
        })
        .addCase(addTag.rejected, (state, action) => {
          state.isFetching = false;
          state.isError = true;
        })
    },
  });

  export const { clearState } = tagListSlice.actions;
  export const tagListSelector = (state: RootState) => state.tagList;

  export default tagListSlice.reducer;