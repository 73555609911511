import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { RootState } from "../../app/store";

export const getResourceTypeById = createAsyncThunk(
  "resource-type/get",
  async (typeId: any, thunkAPI) => {
    try {
      let url = `/api/resource-types/${typeId}`;
      const response = await axios({
        method: "GET",
        url: url,
      });
      let data = await response.data;
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const approveResourceType = createAsyncThunk(
  "resource-topic/approve",
  async ({typeId, updatedData}: any, thunkAPI) => {
    try {
      const response = await axios(
        {
          method: "PUT",
          url: `/api/resource-types/approve/${typeId}`,
          data: updatedData
        }
      );
      let data = await response.data;
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const cancelApproveResourceType = createAsyncThunk(
  "resource-topic/cancel-approve",
  async ({typeId, updatedData}: any, thunkAPI) => {
    try {
      const response = await axios(
        {
          method: "PUT",
          url: `/api/resource-types/cancel-approve/${typeId}`,
          data: updatedData
        }
      );
      let data = await response.data;
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const resourceTypeSlice = createSlice({
    name: "resourceType",
    initialState: {
      resourceType: {
        name: "",
        isApproved: false,
        requestBy: { name: '', _id: ''},
        description: '',
        _id: '',
        requestedAt: '',
        cancelInfo: {
          by: {
            id: "",
            name: "",
          },
          at: "",
        },
        approvedInfo: {
          by: {
            id: "",
            name: "",
          },
          at: "",
        },
        type: "",
      },
      resourceTypeList: { topic: [], total: 0 },
      isFetching: false,
      isSuccess: false,
      isError: false,
      errorMessage: "",
    },
    reducers: {
      clearState: (state) => {
        state.isError = false;
        state.isSuccess = false;
        state.isFetching = false;
      },
    },
    extraReducers: (builder) => {
      builder
        .addCase(getResourceTypeById.pending, (state) => {
          state.isFetching = true;
        })
        .addCase(getResourceTypeById.fulfilled, (state, action) => {
          state.resourceType = action.payload.data;
          state.isFetching = false;
          state.isSuccess = true;
        })
        .addCase(getResourceTypeById.rejected, (state, action) => {
          state.isFetching = false;
          state.isError = true;
        })
    },
  });

  export const { clearState } = resourceTypeSlice.actions;

  export const resourceTypeSelector = (state: RootState) =>
    state.resourceType;
  // export const resourceTypeSelector = (state: RootState) => state.resourceTopic

  export default resourceTypeSlice.reducer;
