import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import jwt from 'jwt-decode';
import { RootState } from '../../app/store';
export const createTrust = createAsyncThunk(
  "trust/post",
  async (postData: any, thunkAPI) => {
    try {
      const response = await axios(
        {
          method: "POST",
          url: `/api/trust`,
          data: postData
        }
      );
      let data = await response.data;
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateTrust = createAsyncThunk(
  "trust/update",
  async ({trust, formData}: any, thunkAPI) => {
    let fileUploadResult: any = {};
    if (formData) {
      const response = await axios(
        {
          method: "POST",
          url: "/api/content/file-upload",
          data: formData
        }
      );
      fileUploadResult = await response.data;
    }
    if (fileUploadResult?.data?.file) {
      trust.uploaded = true;
      trust.file = fileUploadResult.data.file;
      trust.filePath = fileUploadResult.data.filePath;
    }
    try {
      const response = await axios(
        {
          method: "PUT",
          url: `/api/trust/${trust._id}`,
          data: trust
        }
      );
      let data = await response.data;
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getTrust = createAsyncThunk(
  "trust/get",
  async (id: any, thunkAPI) => {
    try {
      const response = await axios(
        {
          method: "GET",
          url: `/api/content/${id}`
        }
      );
      let data = await response.data;
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getTrustList = createAsyncThunk(
  "trust/getlist",
  async (query: any, thunkAPI) => {
    const jwtDecode: any = jwt(localStorage.getItem("token") || "");

    try {
      let url;
      if(jwtDecode?.roles?.includes('L1')){
        url = `/api/trust?level1User=${query.trustId}&sortBy=${query.sortBy}&sortOrder=${query.sortOrder}&page=${query.page}&perPage=${query.perPage}`;
      } else {
        url = `/api/trust?trustId=${query.trustId}&sortBy=${query.sortBy}&sortOrder=${query.sortOrder}&page=${query.page}&perPage=${query.perPage}`;
      }
      if (query.searchString) {
        query.searchString =   (query.searchString).replace(/^\s+/g, '');
        url = `${url}&searchString=${encodeURIComponent(query.searchString)}`;
        console.log("🚀 ~ file: TrustSlice.ts ~ line 108 ~ url", url)
      }
      if (query.filter && Object.keys(query.filter).length > 0) {
        url = `${url}&filter=${JSON.stringify(query.filter)}`;
      }
      const response = await axios(
        {
          method: "GET",
          url: url,
          data: query
        }
      );
      let data = await response.data;
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      console.log("Error", e);
      thunkAPI.rejectWithValue(e);
    }
  }
);

export const getClientCredentials = createAsyncThunk(
  "trust/getClientCredentials",
  async (postData: any, thunkAPI) => {
    try {
      const response = await axios(
        {
          method: "POST",
          url: `/api/oauth/create`,
          data: postData
        }
      );
      let data = await response.data;
      if (response.status === 200) {
        console.log(data)
        return data;
      } else {
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const updateClientCredentials = createAsyncThunk(
  "trust/getClientCredentials",
  async (postData: any, thunkAPI) => {
    try {
      const response = await axios(
        {
          method: "POST",
          url: `/api/oauth/regenerateCredentials`,
          data: postData
        }
      );
      let data = await response.data;
      if (response.status === 200) {
        console.log(data)
        return data;
      } else {
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const deleteTrust = createAsyncThunk(
  "trust/remove",
  async ({trustId}: any, thunkAPI) => {
    try {
      const response = await axios(
        {
          method: "DELETE",
          url: `/api/trust/${trustId}`,
        }
      );
      let data = await response.data;
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const trustActivateOrDeactivateAPI = createAsyncThunk(
  "trust/remove",
  async (patchData: any, thunkAPI) => {
    try {
      const response = await axios(
        {
          method: "patch",
          url: `/api/trust/trustActivateOrDeactivate`,
          data: patchData
        }
      );
      let data = await response.data;
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const userActivateOrDeactivateAPI = createAsyncThunk(
  "trust/remove",
  async (patchData: any, thunkAPI) => {
    try {
      const response = await axios(
        {
          method: "patch",
          url: `/api/trust/userActivateOrDeactivate`,
          data: patchData
        }
      );
      let data = await response.data;
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getServiceTrusts = createAsyncThunk(
  "trust/getServiceTrusts",
  async (postData: any, thunkAPI) => {
    try {
      const response = await axios(
        {
          method: "get",
          url: `/api/trust/content/get-all-trusts`,
        }
      );
      let data = await response.data;
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
)


export const trustSlice = createSlice({
  name: "trust",
  initialState: {
    trust: { title: "", tags: [], trustBody: "", publishForInSpecific: "", file: "", filePath: "", type: ""},
    trustList: {content: [], total: 0},
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: "",
  },
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createTrust.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(createTrust.fulfilled, (state, action) => {
        state.trust = action.payload.data;
        state.isFetching = false;
        state.isSuccess = true;
      })
      .addCase(createTrust.rejected, (state, action) => {
        // state.errorMessage = action.payload.message;
        state.isFetching = false;
        state.isError = true;
      })
      .addCase(updateTrust.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(updateTrust.fulfilled, (state, action) => {
        state.trust = action.payload.data;
        state.isFetching = false;
        state.isSuccess = true;
      })
      .addCase(updateTrust.rejected, (state, action) => {
        // state.errorMessage = action.payload.message;
        state.isFetching = false;
        state.isError = true;
      })
      .addCase(getTrust.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(getTrust.fulfilled, (state, action) => {
        state.trust = action.payload.data;
        state.isFetching = false;
        state.isSuccess = true;
      })
      .addCase(getTrust.rejected, (state, action) => {
        // state.errorMessage = action.payload.message;
        state.isFetching = false;
        state.isError = true;
      })
      .addCase(getTrustList.pending, (state) => {
        state.isFetching = true;
        state.trustList = {content: [], total: 0};
      })
      .addCase(getTrustList.fulfilled, (state, action) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.trustList = action.payload.data;
      })
      .addCase(getTrustList.rejected, (state, action) => {
        // state.errorMessage = action.payload.message;
        state.isFetching = false;
        state.isError = true;
      });
  },
});



export const { clearState } = trustSlice.actions;

export const trustSelector = (state: RootState) => state.trust;
export const trustListSelector = (state: RootState) => state.trust.trustList;

export default trustSlice.reducer;