import React from 'react';

import { Tab, Tabs, TabList } from 'react-tabs';

interface CustomTabsProps {
  addclass?: string;
  children?: any;
  tablist: any;
  tabindex?: any;
  onChangeTab: (e: string, index: any) => void;
}

const CustomTabs = (props: CustomTabsProps) => {
  const { addclass, children, tablist, tabindex, onChangeTab } = props;
  const tabIndex = 0;
  return (
    <>
      <div className={`custom-tab position-relative ${addclass}`}>
        <Tabs defaultIndex={tabindex}>
          <TabList>
            {tablist?.map((tabs: any, index: any) => (
              <Tab
                default={false}
                key={Math.random()}
                onClick={() => {
                  onChangeTab(tabs, index);
                }}
              >
                <button className="custom-list w-full">
                  <span className="label-area">
                    {tabs} 
                  </span>
                </button>
              </Tab>
            ))}
          </TabList>
          <div className="custom-panel">{children}</div>
        </Tabs>
      </div>
    </>
  );
};

export default CustomTabs;
