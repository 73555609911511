import React, { useEffect } from "react";
import $ from "jquery";
// import { Toaster } from "react-hot-toast";
import { ToastContainer } from 'react-toastify';

import { BrowserRouter, useLocation } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { AppRoutes } from "./routes";
import { useAppSelector } from "./app/hooks";
import { userSelector } from "./features/Auth/AuthSlice";

import Header from "./components/Header";
import "./App.scss";
import 'react-toastify/dist/ReactToastify.css';
import { FooterBentoMenu } from "./components/BentoMenu/BentoMenu";
import Loader from "./components/loading/Loading";


function App() {

  if (process.env.REACT_APP_DISABLE_LOGS === 'true') {
    console.log(
      '%c Stop!',
      'color: red; font-size: 36px; text-shadow: 0 0 1px #FF0000, 0 0 1px #000000;'
    );
    console.log(
      '%c This is a browser feature intended for developers.',
      'color: black; font-size: 20px'
    );
    console.log = () => {};
    console.error = () => {};
    console.debug = () => {};
  }
  
  $(document).ready(function () {
    function isEmpty(el: any) {
      return !$.trim(el.text());
    }
    $("h1,h2,h3,h4,h5,h6,label,p").each((t: any, e: any) => {
      if (isEmpty($(e))) {
        // console.log($(e), "is empty");
        $(e).append("&#8203;");
      }
    });
  });

  useEffect(() => {
    const listener = (event: any) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        console.log("Enter key was pressed. Run your function.");
        event.preventDefault();
        // callMyFunction();
        const el = $(event.target);

        if ($(el).hasClass("focusto")) {
          if ($(el).attr("focus-to")) {
            // remove attr
            $('[focus-back-element="true"]').each(function (i, e) {
              $(e).removeAttr("focus-back-element");
            });
          }
          if (!$(el).hasClass("question-menu-block")) {
            $(".focusback_btn").removeClass("flex");
            $(".focusback_btn").addClass("hidden");
          }
          if (!$(el).hasClass("user-role-section")) {
            $(".focusback_btn").removeClass("flex");
            $(".focusback_btn").addClass("hidden");
          }

          if (
            $('[focus-back-element="true"]').length === 0 &&
            $(el).attr("focus-to")
          ) {
            console.log("----------no element------------");

            if ($(el).hasClass("question-menu-info")) {
              $(".focusback_btn").removeClass("hidden");
              $(".focusback_btn").addClass("flex");
            }
            if ($(el).hasClass("user-role-section")) {
              $(".focusback_btn").removeClass("hidden");
              $(".focusback_btn").addClass("flex");
            }

            if ($(el).hasClass("setting_popup_focus")) {
              setTimeout(function () {
                console.log(
                  "------rrrrrrrrrrrr--------",
                  $(".setting_back_focus")
                );
                $(".setting_back_focus").removeClass("hidden");
              }, 1000);
              $(".setting_back_focus").addClass("flex");
            }

            // add identity to element
            $(el).attr("focus-back-element", "true");
          } else {
            console.log(
              "----------element--exist----------",
              $('[focus-back-element="true"]').length
            );
            // get element
            const element: any = $('[focus-back-element="true"]')[0];
            if (element) {
              console.log("focus-back-element", element);
              element.focus();

              if ($(el).hasClass("focusback_btn")) {
                $(".focusback_btn").removeClass("flex");
                $(".focusback_btn").addClass("hidden");
              }
              if ($(el).hasClass("setting_back_focus")) {
                console.log("-setting_back_focus- 2", el);

                $(".setting_back_focus").removeClass("flex");
                $(".setting_back_focus").addClass("hidden");
              }

              if ($(el).attr("focus-to") && !$(el).attr("focus-back-element")) {
                // remove attr
                $('[focus-back-element="true"]').each(function (i, e) {
                  $(e).removeAttr("focus-back-element");
                });
                $(".focusback_btn").each(function (i, e) {
                  $(e).removeClass("flex");
                  $(e).addClass("hidden");
                });
              }

              // remove attr
              $('[focus-back-element="true"]').each(function (i, e) {
                $(e).removeAttr("focus-back-element");
              });
            }
          }

          el.trigger("click");

          if (
            el.attr("focus-to") &&
            document.getElementById(el.attr("focus-to") || "null")
          ) {
            const focusToEl = document.getElementById(
              el.attr("focus-to") || "null"
            );
            if (focusToEl) {
              focusToEl.focus();
            }
          }
        }

        const elm = $(event.target);

        if ($(elm).hasClass("onkeyup")) {
          el.trigger("click");
        }
      }
    };
    document.addEventListener("keyup", listener);
    return () => {
      document.removeEventListener("keyup", listener);
    };
  }, []);

  const ScrollTop = ({ children }) => {
    const { pathname } = useLocation();

    // Automatically scrolls to top whenever pathname changes
    useEffect(() => {
    //  window.scrollTo(0, 0);
    }, [pathname]);
    return <>
      {children}
    </>
  }

  const { isLoggedIn } = useAppSelector(userSelector);
  return (
    <BrowserRouter>
      <div className="App">
        {isLoggedIn ? (
          <>
            <Header />
            <div className="layout">
              <div
                className={
                  "layout-container" + (isMobile ? " mx-4" : " mx-auto")
                }
              >
                <ScrollTop>
                  <AppRoutes />
                </ScrollTop>
              </div>
            </div>
            <FooterBentoMenu />
          </>
        ) : (
          <AppRoutes />
        )}
        {/* <Toaster
          position="top-right"
          toastOptions={{
            className: "custom-toast",
          }}
          containerStyle={{
            top: 20,
            right: 20,
          }}
        /> */}
        <ToastContainer limit={1} pauseOnHover={false} autoClose={3000} />

      </div>
    </BrowserRouter>
  );
}

export default App;
