import React from "react";
import { Redirect, Route, useHistory } from "react-router-dom";
import jwt from "jwt-decode";

export const PrivateRoute = ({ component: Component, ...rest }) => {
  let shortenRoles = [];
  const checkAccess = () => {
    if (localStorage.getItem("token")) {
      const jwtDecode = jwt(localStorage.getItem("token") || "");
      shortenRoles = jwtDecode.scopePermissions || [];
    }
    var result = shortenRoles.filter(function (item) {
      return rest?.accessRoles.indexOf(item) > -1;
    });
    return result.length > 0;
  };

  return (
    <Route
      {...rest}
      render={(props) =>
        localStorage.getItem("token") ? (
          <>
            {checkAccess() ? (
              <Component {...props} />
            ) : (
              <Redirect
                to={{ pathname: "/no-access", state: { from: props.location } }}
              />
            )}
          </>
        ) : (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        )
      }
    />
  );
};
