import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { RootState } from "../../app/store";

export const getResourceLibraryList = createAsyncThunk(
  "resourceLibrary/getlist",
  async (query: any, thunkAPI) => {
    try {
      let getAllResourceLibraryUrl = `/api/content/all/published`;
      // if(trustId !== "undefined") {
      //   getAllNotificationUrl = `/api/trust/${trustId}/notifications`;
      // }
      let url = `${getAllResourceLibraryUrl}?sortBy=${query.sortBy}&sortOrder=${query.sortOrder}&page=${query.page}&perPage=${query.perPage}`;
      if (query.searchString) {
        url = `${url}&searchString=${query.searchString}`;
      }
      if (query.filter && Object.keys(query.filter).length > 0) {
        url = `${url}&filter=${JSON.stringify(query.filter)}`;
      }
      const response = await axios({
        method: "GET",
        url: url,
        data: query,
      });
      let data = await response.data;
      if (response.status === 200) {
        console.log(data)
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      console.log("Error", e);
      thunkAPI.rejectWithValue(e);
    }
  }
);

export const resourceLibrarySlice = createSlice({
  name: "resourceLibrary",
  initialState: {
    resourcelibrary: {},
    resourceLibraryList: { resourceLibrarys: [], total: 0 },
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: "",
  },
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getResourceLibraryList.pending, (state) => {
        state.isFetching = true;
        state.resourceLibraryList = { resourceLibrarys: [], total: 0 };
      })
      .addCase(getResourceLibraryList.fulfilled, (state, action) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.resourceLibraryList = action.payload.data;
      })
      .addCase(getResourceLibraryList.rejected, (state, action) => {
        // state.errorMessage = action.payload.message;
        state.isFetching = false;
        state.isError = true;
      });
  },
});

export const { clearState } = resourceLibrarySlice.actions;

export const resourceLibrarySelector = (state: RootState) => state.resourceLibrary;
export const resourceLibraryListSelector = (state: RootState) =>
  state.resourceLibrary.resourceLibraryList;

export default resourceLibrarySlice.reducer;
