import React, { FC } from "react";

export type FormErrorMessageProps = {
  className?: string;
};

export const FormErrorMessage: FC<FormErrorMessageProps> = ({
  children,
  className,
}) => (
  <div className="mb-2" style={{ color: "#C31F1F", fontSize: "16px" }}>
    {children}
  </div>
);
