import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { RootState } from '../../app/store';

export const createLevel1User = createAsyncThunk(
    "level1/post",
    async (postData: any, thunkAPI) => {
      try {
        const response = await axios(
          {
            method: "POST",
            url: `/api/level1`,
            data: postData
          }
        );
        let data = await response.data;
        if (response.status === 200) {
          return data;
        } else {
          return thunkAPI.rejectWithValue(response.data);
        }
      } catch (error: any) {
        return thunkAPI.rejectWithValue(error);
      }
    }
  );

  export const getLevel1UserList = createAsyncThunk(
    "level1/getlist",
    async (query: any, thunkAPI) => {
      try {
        let url = `/api/level1?level1Id=${query.level1Id}&sortBy=${query.sortBy}&sortOrder=${query.sortOrder}&page=${query.page}&perPage=${query.perPage}`;
        if (query.searchString) {
          query.searchString =   (query.searchString).replace(/^\s+/g, '');
          const searchTxt = encodeURIComponent(query.searchString);
          url = `${url}&searchString=${searchTxt}`;
        }
        if (query.filter && Object.keys(query.filter).length > 0) {
          url = `${url}&filter=${JSON.stringify(query.filter)}`;
        }
        const response = await axios(
          {
            method: "GET",
            url: url,
            data: query
          }
        );
        let data = await response.data;
        console.log("🚀 ~ file: level1UserSlice.ts ~ line 47 ~ data", data)
        if (response.status === 200) {
          return data;
        } else {
          return thunkAPI.rejectWithValue(data);
        }
      } catch (e: any) {
        console.log("Error", e);
        thunkAPI.rejectWithValue(e);
      }
    }
  );

  export const level1ActivateOrDeactivateAPI = createAsyncThunk(
    "trust/remove",
    async (patchData: any, thunkAPI) => {
      try {
        const response = await axios(
          {
            method: "patch",
            url: `/api/level1/level1ActivateOrDeactivate`,
            data: patchData
          }
        );
        let data = await response.data;
        if (response.status === 200) {
          return data;
        } else {
          return thunkAPI.rejectWithValue(response.data);
        }
      } catch (e: any) {
        console.log("Error", e.response.data);
        thunkAPI.rejectWithValue(e.response.data);
      }
    }
  );

  export const deleteLevel1 = createAsyncThunk(
    "level1/remove",
    async ({level1Id}: any, thunkAPI) => {
      try {
        const response = await axios(
          {
            method: "DELETE",
            url: `/api/level1/${level1Id}`,
          }
        );
        let data = await response.data;
        if (response.status === 200) {
          return data;
        } else {
          return thunkAPI.rejectWithValue(response.data);
        }
      } catch (e: any) {
        console.log("Error", e.response.data);
        thunkAPI.rejectWithValue(e.response.data);
      }
    }
  );


export const level1UserSlice = createSlice({
    name: "trust",
    initialState: {
      level1User: { title: "", tags: [], level1UserBody: "", publishForInSpecific: "", file: "", filePath: "", type: ""},
      level1UserList: {content: [], total: 0},
      isFetching: false,
      isSuccess: false,
      isError: false,
      errorMessage: "",
    },
    reducers: {
      clearState: (state) => {
        state.isError = false;
        state.isSuccess = false;
        state.isFetching = false;
      },
    },
    extraReducers: (builder) => {
      builder
        .addCase(createLevel1User.pending, (state) => {
          state.isFetching = true;
        })
        .addCase(createLevel1User.fulfilled, (state, action) => {
          state.level1User = action.payload.data;
          state.isFetching = false;
          state.isSuccess = true;
        })
        .addCase(createLevel1User.rejected, (state, action) => {
          // state.errorMessage = action.payload.message;
          state.isFetching = false;
          state.isError = true;
        })
        .addCase(getLevel1UserList.pending, (state) => {
          state.isFetching = true;
          state.level1UserList = {content: [], total: 0};
        })
        .addCase(getLevel1UserList.fulfilled, (state, action) => {
          state.isFetching = false;
          state.isSuccess = true;
          state.level1UserList = action.payload.data;
        })
        .addCase(getLevel1UserList.rejected, (state, action) => {
          // state.errorMessage = action.payload.message;
          state.isFetching = false;
          state.isError = true;
        });
    },
  });

export const { clearState } = level1UserSlice.actions;

export const level1UserSelector = (state: RootState) => state.level1User;
export const level1UserListSelector = (state: RootState) => state.level1User.level1UserList;

export default level1UserSlice.reducer;