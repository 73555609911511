export const scopeProperty = {
  ALL_ACCESS: "all",
  ALL_TRUST_ACCESS: "trust:all",

  TRUST_CREATE: "trust:create",
  TRUST_VIEW: "trust:view",
  TRUST_EDIT: "trust:edit",
  TRUST_DELETE: "trust:delete",
  
  USER_CREATE: "user:create",
  USER_VIEW: "user:view",
  user_EDIT: "user:edit",
  user_DELETE: "user:delete",
  

  RESOURCE_CREATE: "resource:create",
  RESOURCE_APPROVE: "resource:approve",
  RESOURCE_PUBLISH: "resource:publish",
  RESOURCE_ADD: "resource:add",
  RESOURCE_DELETE: "resource:delete",
  RESOURCE_VIEW: "resource:view",
  RESOURCE_PUBLISHED_VIEW: "published:view",



  CONTENT_TYPE_REQUEST: "content-type:request",
  CONTENT_TYPE_APPROVE: "content-type:approve",

  CONTENT_TOPIC_REQUEST: "content-topic:request",
  CONTENT_TOPIC_APPROVE: "content-topic:approve",

};
