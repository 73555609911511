export const Constants = {
  ROWS_PER_PAGE: 10,
  CONTENT_TYPE_VIEW_LIST: ["Patient Leaflets", "Research Papers"],
  USER_ROLES: ["SA", "TA", "CM", "CA"],
  USER_ADD_SUCCESS: "User has been created successfully.",
  USER_PROFILE_UPDATE_SUCCESS: "User profile has been updated successfully.",
  USER_PROFILE_UPDATE_FAILED: "Failed to update user profile",
  USER_PASSWORD_UPDATE_SUCCESS: "Password updated successfully",
  USER_PASSWORD_MISMATCH: "Password mismatch",
  USER_PASSWORD_UPDATE_FAILED: "Failed to update password",
  USER_REMOVE_SUCCESS: "User has been removed successfully.",
  RESET_LINK_SENT_SUCCESS: "Reset link for password has been sent.",
  TRUST_ADD_SUCCESS: "Trust created successfully.",
  LEVEL1_ADD_SUCCESS: "System admin created successfully.",
  REVIEW_ADD_SUCCES: "Resource has been sent back to the resource creator.",
  CONTENT_APPROVED: "You have approved this resource.",
  RESOURCE_CONTENT_SAVED: "Resource content saved successfully.",
  RESOURCE_CONTENT_UPDATED: "Content of your resource has been updated successfully.",
  RESOURCE_CONTENT_UPDATED_AND_SFA: "Your resource has been updated successfully and has been sent for approval.",
  RESOURCE_CONTENT_UPDATED_AND_APPROVED: "Content of your resource has been updated and approved successfully.",
  CONTENT_TOPIC_SUCCESS: "Content topic has been saved successfully.",
  CONTENT_TOPIC_DUPLICATE: "Content topic already exists.",
  RESOURCE_TYPE_UPDATED: "Resource type updated successfully.",
  RESOURCE_TYPE_APPROVED: "Resource type approved successfully.",
  RESOURCE_TYPE_DISAPPROVED: "Resource type disapproved successfully.",
  RESOURCE_TYPE_DELETED: "Resource type deleted successfully.",
  RESOURCE_TYPE_DUPLICATE: "Resource type exists already.",
  RESOURCE_TOPIC_UPDATED: "Resource topic updated successfully.",
  RESOURCE_TOPIC_DELETED: "Resource topic deleted successfully.",
  RESOURCE_TOPIC_APPROVED: "Resource topic approved successfully.",
  RESOURCE_TOPIC_DISAPPROVED: "Resource topic disapproved successfully.",
  TRUST_DELETED: "Trust archived successfully.",
  SYSTEM_ADMIN_DELETED: "System admin archived successfully.",
  USER_DELETED: "User archived successfully.",
  EMAIL_ALREADY_EXIST: "Email address already exists!",
  CONTENT_TYPE_STATIC_LIST: ["Apps", "Patient Leaflets", "Tips", "Articles", "Research Papers", "Useful links", "Events & Groups", "Stories", "Videos"],
  RESOURCE_TAG_EXIST_ALREADY: "Resource Tag exists already",
  ROLE_CREATED: "Role created successfully",
  ROLE_UPDATED: "Role updated successfully",
};

export const rolesTabsList = [
  {
    name: "Admin",
  },
  {
    name: "Clinicians",
  },
  {
    name: "Patient",
  },
];
export const rolesTabEvents = [
  {
    name: "Trust management",
  },
  {
    name: " User management",
  },
  {
    name: "Resource content management",
  },
  {
    name: "Profile management",
  },
];

export const scopeAndModelsList = {
  "Trust management": {
    TRUST_CREATE: { name: "Create trust", scope: "trust:create", description: "lorum", enable: true },
    TRUST_VIEW: { name: "View trust", scope: "trust:view", description: "lorum", enable: true },
    TRUST_EDIT: { name: "Edit trust", scope: "trust:edit", description: "lorum", enable: true },
    TRUST_DELETE: { name: "Delete trust", scope: "trust:delete", description: "lorum", enable: true },
  },
  "User management": {
    USER_CREATE: { name: "Create user", scope: "user:create", description: "lorum", enable: true },
    USER_VIEW: { name: "View user", scope: "user:view", description: "lorum", enable: true },
    USER_EDIT: { name: "Edit user", scope: "user:edit", description: "lorum", enable: true },
    USER_DELETE: { name: "Delete user", scope: "user:delete", description: "lorum", enable: true },
  },
  "Resource management": {   
    RESOURCE_CREATE: { name: "Create resource", scope: "resource:create", description: "lorum", enable: true },
    RESOURCE_VIEW: { name: "View resource", scope: "resource:view", description: "lorum", enable: true },
    RESOURCE_DELETE: { name: "Delete resource", scope: "resource:delete", description: "lorum", enable: true },
    RESOURCE_APPROVE: { name: "Approve resource", scope: "resource:approve", description: "lorum", enable: true },
    RESOURCE_PUBLISH: { name: "Publish resource", scope: "resource:publish", description: "lorum", enable: true },
    RESOURCE_ADD: { name: "Add resource", scope: "resource:add", description: "lorum", enable: true },
    
  },
  "Content management": {
    CONTENT_TYPE_REQUEST: { name: "Request content type", scope: "content-type:request", description: "lorum", enable: true },
    CONTENT_TOPIC_REQUEST: { name: "Request content topic", scope: "content-topic:request", description: "lorum", enable: true },
    CONTENT_TYPE_APPROVE: { name: "Approve content type", scope: "content-type:approve", description: "lorum", enable: true },
    CONTENT_TOPIC_APPROVE: { name: "Approve content topic", scope: "content-topic:approve", description: "lorum", enable: true },
  },
  "All management": {
    ALL_ACCESS: { name: "All access", scope: "All", description: "lorum", enable: true },
  },

  "Published management": {
    PUBLISHED_VIEW: { name: "View published resources", scope: "published:view", description: "lorum", enable: true },
  }
};
