import React, { useState } from "react";

import { Link } from "react-router-dom";

import { ReactComponent as BentoClose } from "../../assets/images/Bento_Close.svg";
import { ReactComponent as Bentomenu } from "../../assets/images/Bento_menu.svg";
import { MobileBentomenu } from "./BentoMenuList";
import jwt from "jwt-decode";
import { Constants } from "../../global/Constants";
import _ from "lodash";
import "../styles/Bento.scss";

interface FooterBentoMenuProps {}

export const FooterBentoMenu: React.FC<FooterBentoMenuProps> = () => {
  const token = localStorage.getItem("token") || "";
  const jwtDecode: any = token ? jwt(token) : {};

  const shortenRole: Array<string> = jwtDecode.roles || [];
  const [showBento, setShowBento] = useState(true);
  return (
    <>
      <div className="footer-menu-block">
        <span
          className="footer-bento-menu-block"
          onClick={() => (showBento ? setShowBento(false) : setShowBento(true))}
        >
          {showBento ? <Bentomenu /> : <BentoClose />}
        </span>
        {!showBento && (
          <div className="footer-bento-menu-section">
            {MobileBentomenu.length > 0 &&
              shortenRole.length > 0 &&
              MobileBentomenu.filter((menu: any) => {
                return _.intersection(menu.role, shortenRole).length > 0;
              }).map((item: any) => {
                return (
                  <React.Fragment key={Math.random()}>
                    {item.source && item.source === "internal" ? (
                      <Link className="no-underline" to={item.link}>
                        <div className="bento-list">
                          {item.iconsrc}
                          <span>{item.title}</span>
                        </div>
                      </Link>
                    ) : (
                      <a className="no-underline" href={item.link}>
                        <div className="bento-list">
                          {item.iconsrc}
                          <span>{item.title}</span>
                        </div>
                      </a>
                    )}
                  </React.Fragment>
                );
              })}
          </div>
        )}
      </div>
    </>
  );
};
