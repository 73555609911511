import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { RootState } from "../../app/store";

export const getResourceTopic = createAsyncThunk(
  "resource-topic/get",
  async (id: any, thunkAPI) => {
    try {
      let url = `/api/resource-topics`;
      if(id && id > 1){
        url = `/api/resource-topics?id=`+id;
      }
      const response = await axios({
        method: "GET",
        url: url,
      });
      let data = await response.data;
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getResourceTopicById = createAsyncThunk(
  "resource-topic/get",
  async (topicId: any, thunkAPI) => {
    try {
      let url = `/api/resource-topics/${topicId}`;
      const response = await axios({
        method: "GET",
        url: url,
      });
      let data = await response.data;
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const updateResourceTopic = createAsyncThunk(
  "resource-topic/update",
  async ({topicId, updatedData}: any, thunkAPI) => {
    try {
      const response = await axios(
        {
          method: "PUT",
          url: `/api/resource-topics/${topicId}`,
          data: updatedData
        }
      );
      let data = await response.data;
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const deleteResourceTopic = createAsyncThunk(
  "resource-topic/remove",
  async ({topicId}: any, thunkAPI) => {
    try {
      const response = await axios(
        {
          method: "DELETE",
          url: `/api/resource-topics/${topicId}`,
        }
      );
      let data = await response.data;
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const saveResourceTopic = createAsyncThunk(
  "resource-topic/post",
  async (postData: any, thunkAPI) => {
    try {
      const response = await axios({
        method: "POST",
        url: `/api/resource-topics/request`,
        data: postData,
      });
      let data = await response.data;
      if (response.status === 200) {
        getResourceTopic("11");
        return data;
      } else {
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const approveResourceTopic = createAsyncThunk(
  "resource-topic/approve",
  async (topicId: any, thunkAPI) => {
    try {
      const response = await axios(
        {
          method: "PUT",
          url: `/api/resource-topics/approve/${topicId}`
        }
      );
      let data = await response.data;
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const cancelApproveResourceTopic = createAsyncThunk(
  "resource-topic/cancel-approve",
  async (topicId: any, thunkAPI) => {
    try {
      const response = await axios(
        {
          method: "PUT",
          url: `/api/resource-topics/cancel-approve/${topicId}`
        }
      );
      let data = await response.data;
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const resourceTopicSlice = createSlice({
  name: "resourceTopic",
  initialState: {
    resourceTopic: {
      name: "",
      isApproved: false,
      requestBy: { name: '', _id: ''},
      _id: '',
      requestedAt: '',
      cancelInfo: {
        by: {
          id: "",
          name: "",
        },
        at: "",
      },
      approvedInfo: {
        by: {
          id: "",
          name: "",
        },
        at: "",
      },
    },
    resourceTopicList: { topic: [], total: 0 },
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: "",
  },
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getResourceTopicById.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(getResourceTopicById.fulfilled, (state, action) => {
        state.resourceTopic = action.payload.data;
        state.isFetching = false;
        state.isSuccess = true;
      })
      .addCase(getResourceTopicById.rejected, (state, action) => {
        state.isFetching = false;
        state.isError = true;
      })
      
      .addCase(saveResourceTopic.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(saveResourceTopic.fulfilled, (state, action) => {
        // state.resourceTopic = [...state.resourceTopic, action.payload.data];
        state.isFetching = false;
        state.isSuccess = true;
      })
      .addCase(saveResourceTopic.rejected, (state, action) => {
        // state.errorMessage = action.payload.message;
        state.isFetching = false;
        state.isError = true;
      })
  },
});

export const { clearState } = resourceTopicSlice.actions;

export const resourceTopicsSelector = (state: RootState) =>
  state.resource.resourceTopics;
export const resourceTopicSelector = (state: RootState) => state.resourceTopic

export default resourceTopicSlice.reducer;
