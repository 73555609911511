import React from "react";
import ReactSelect from "react-select";
import { Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { FormErrorMessage } from "./FormErrorMessage";

import "./styles/FormSelect.scss";
import { getRandomText } from "../global/HelperFunctions";

export const FormSelect = ({
  name,
  register,
  rules,
  errors,
  className,
  label,
  field,
  options,
  valueKey,
  labelKey,
  control,
  linkText,
  isSearchable,
  id = getRandomText(10),
  selectedValue,
  ...props
}: any): JSX.Element => {
  const tabIndex = 0;
  const customStyles = {
    control: (base: any, state: any) => ({
      ...base,
      height: "45px",
      marginBottom: "14px",
    }),
    placeholder: (base: any, state: any) => ({
      ...base,
      color: "#6c757d",
      fontsize: "16px",
      fontStyle: "italic",
    }),
    dropdownIndicator: (base: any, state: any) => ({
      ...base,
      color: "#035FAF",
      paddingRight: "20px",
    }),
    indicatorSeparator: (base: any, state: any) => ({
      ...base,
      background: "none",
    }),
    valueContainer: (base: any, state: any) => ({
      ...base,
      paddingLeft: "20px",
    }),
    menu: (base) => ({
      ...base,
      // override border radius to match the box
      borderRadius: 0,
      // kill the gap
      // marginTop: 0,
    }),
    menuList: (base) => ({
      ...base,
      padding: 0,
      overflowY: "auto",
      overflowX: "hidden",
    }),
    option: (base) => ({
      ...base,
      textOverflow: "ellipsis",
      overflow: "hidden",
    }),
  };
  return (
    <div className="custom-select">
      <label htmlFor={id}>{label}</label>
      {/* @ts-ignore */}
      <Controller
        name={name}
        render={({ field: { onChange, onBlur, value, name, ref } }) => {
          return (
            <ReactSelect
              // menuIsOpen
              isSearchable={isSearchable}
              inputId={id}
              styles={customStyles}
              value={options.find((c: any) => c[valueKey] === value)}
              getOptionLabel={(option: any) => `${option[labelKey]}`}
              getOptionValue={(option: any) => `${option[valueKey]}`}
              defaultValue={options.find(
                (c: any) => c[valueKey] === selectedValue
              )}
              onChange={(val: any) => {
                console.log("🚀 ~ file: FormSelect.tsx:111 ~ val:", val)
                console.log(val[valueKey])
                onChange(val[valueKey]);
                if (props.onSelectChange) {
                  props.onSelectChange(val[valueKey], val);
                }
              }}
              ref={ref}
              options={options || []}
              {...props}
            />
          );
        }}
        control={control}
        rules={rules}
      />
      <ErrorMessage
        errors={errors}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        name={name as any}
        render={({ message }) => (
          <FormErrorMessage className="mt-1">{message}</FormErrorMessage>
        )}
      />
      {/* <FormErrorMessage className="mt-1">Error</FormErrorMessage> */}
      <div className="input-link cursor-pointer">{linkText}</div>
    </div>
  );
};
